import {
  useEffect,
  ComponentType
} from 'react';
import {
  GoogleAdsContext, TrackConversionOptions
} from '../lib';

export const withGoogleAdsConversion = <P extends object>(
  WrappedComponent: ComponentType<P>
) => (props: P) => {
  useEffect(() => {
    const scriptUrl = 'https://www.googletagmanager.com/gtag/js?id=AW-16560156810';
    const script = document.createElement('script');
    script.async = true;
    script.src = scriptUrl;
    document.head.appendChild(script);

    script.onload = () => {
      window.dataLayer = window.dataLayer || [];
      window.gtag = function (...args: any[]) { window.dataLayer.push(args); };
      window.gtag('js', new Date());
      window.gtag('config', 'AW-16560156810');
    };

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  const trackConversion = ({ adsLabel, transactionId }: TrackConversionOptions) => {
    const gtag = (window as any).gtag as typeof window.gtag;
    if (gtag) {
      gtag('event', 'conversion', {
        'send_to': `AW-16560156810/${adsLabel}`,
        'transaction_id': transactionId ?? ''
      });
    }
  };

  return (
    <GoogleAdsContext.Provider value={{ trackConversion }}>
      <WrappedComponent {...props} />
    </GoogleAdsContext.Provider>
  );
};