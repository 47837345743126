import {
  FC,
  useEffect,
  useState
} from 'react';
import {
  useDispatch
} from 'react-redux';
import {
  Modal,
  ModalDialog,
} from '@mui/joy';
import {
  useTheme,
  useMediaQuery
} from '@mui/material';
import {
  useToaster
} from '@shared/ui';
import {
  OmegaApiResponseStatusEnum
} from '@shared/api';
import {
  PaymentMethodKeyEnum
} from '@shared/types';
import {
  CheckoutWorldPayForm,
  CheckoutResultWorldPay,
} from '@entities/payments';
import {
  selectFullProfile,
  useLazyBalanceQuery,
  useSessionSelector
} from '@entities/session';
import {
  type PreparePurchasePackageResponse,
} from '../api';
import {
  useLazyPreparePurchasePackageQuery,
} from '../api';
import {
  discardCheckout,
  selectCoinPackage,
  useBuyCoinsSelector
} from '../model';

export type CheckoutWorldPayModalProps = {
  open: boolean;
  onDepositStart: () => void;
  onDepositSuccess: (depositResult?: CheckoutResultWorldPay) => void;
  onDepositFail: (depositResult?: CheckoutResultWorldPay) => void;
};

export const CheckoutWorldPayModal: FC<CheckoutWorldPayModalProps> = ({
  open,
  onDepositStart,
  onDepositFail,
  onDepositSuccess
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(490));

  const toast = useToaster();
  const dispatch = useDispatch();

  const props = useBuyCoinsSelector(selectCoinPackage);
  const userProfile = useSessionSelector(selectFullProfile);

  const [preparePurchasePackage, setPreparePurchasePackage] = useState<Maybe<Partial<PreparePurchasePackageResponse>>>(null);

  const [getBalanceQuery] = useLazyBalanceQuery();
  const [preparePurchasePackageQuery] = useLazyPreparePurchasePackageQuery();

  useEffect(() => {
    const processDeposit = async (
      currency: string = 'USD',
      amount: number = 0,
      brandId: number = 0,
      packageId: number = 0,): Promise<void> => {
      onDepositStart();

      const preparePurchasePackageParams = {
        currency,
        amount,
        brandId,
        packageId,
        paymentMethod: PaymentMethodKeyEnum.WorldPayCNPCard,
      };

      const preparePurchasePackageResponse = await preparePurchasePackageQuery(preparePurchasePackageParams);
      const { status, ...preparePurchasePackageProps } = preparePurchasePackageResponse.data as PreparePurchasePackageResponse;

      if ([OmegaApiResponseStatusEnum.Success, OmegaApiResponseStatusEnum.ContinueToPaymentSite].includes(status)) {
        setPreparePurchasePackage(preparePurchasePackageProps);
      } else {
        toast.error({ message: status, autoHideDuration: 5000 });
        await handleClose();
      }
    }

    const isAllowToProcessDeposit =
      props?.packageId &&
      props?.purchaseCurrency &&
      props?.purchasePrice &&
      props?.brandId &&
      open;

    if (isAllowToProcessDeposit)
      processDeposit(props?.purchaseCurrency, props?.purchasePrice, props?.brandId, props?.packageId);

    return () => {
      setPreparePurchasePackage(null);
    };
    // eslint-disable-next-line
  }, [
    props?.purchaseCurrency,
    props?.purchasePrice,
    props?.brandId,
    props?.packageId,
    open
  ]);

  const handleClose = async (): Promise<void> => {
    dispatch(discardCheckout());
    await getBalanceQuery();
  };

  const handleDepositSuccess = async (
    depositResult?: {
      amount: string;
      cardNumber?: string;
      referenceId?: string;
    }
  ): Promise<void> => {
    dispatch(discardCheckout());
    await getBalanceQuery();
    onDepositSuccess?.(depositResult);
  };

  const handleDepositFail = (depositResult?: {
    amount: string;
    cardNumber?: string;
    referenceId?: string;
  }
  ): void => {
    dispatch(discardCheckout());
    onDepositFail?.(depositResult);
  };

  return (
    <Modal open={open} disablePortal>
      <ModalDialog
        layout={(
          isMobile
            ? 'fullscreen'
            : 'center'
        )}
        sx={({ breakpoints, palette }) => ({
          width: 2000,
          height: '100%',
          maxWidth: 488,
          maxHeight: 678,
          backgroundColor: palette.common[925],
          [breakpoints.down(491)]: {
            maxHeight: '100%',
            borderRadius: 0,
            boxShadow: 'none',
            width: '100%'
          },
        })}
        {...props}>
        {preparePurchasePackage && (
          <CheckoutWorldPayForm
            amount={props?.purchasePrice ?? 0}
            checkoutInfo={{
              merchantTxId: preparePurchasePackage?.merchantTxId ?? 0,
              paymentId: preparePurchasePackage?.paymentId ?? 0,
              payPageId: preparePurchasePackage?.payPageId ?? '',
              reportGroup: preparePurchasePackage?.reportGroupId ?? ''
            }}
            billingInfo={{
              billingAddress1: userProfile?.address,
              billingCity: userProfile?.city,
              billingCountry: userProfile?.country,
              billingEmail: userProfile?.email,
              billingFirstName: userProfile?.firstName,
              billingLastName: userProfile?.lastName,
              billingPhone: userProfile?.phone,
              billingState: userProfile?.province,
              billingPostalCode: userProfile?.postalCode
            }}
            onCheckoutFail={handleDepositFail}
            onCheckoutSuccess={handleDepositSuccess}
            onClose={handleClose}
          />
        )}
      </ModalDialog>
    </Modal>
  );
};