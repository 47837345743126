import {
  createSlice,
  PayloadAction
} from '@reduxjs/toolkit';
import {
  resetStore
} from '@shared/store-reset';
import {
  paymentsApiSlice
} from '../api';
import {
  mapPaymentMethodOrderSet
} from '../lib';
import {
  PaymentMethodOrderSetTypeEnum,
  type CheckoutResult,
  type PaymentMethod,
  type PaymentsState
} from './types';

const initialState: PaymentsState = {
  actionPurpose: null,
  paymentMethodOrderSet: [],
  paymentMethod: null,
  recentlyUsedPaymentMethod: null,
  type: null,
  checkoutResultWorldPay: null,
  openCheckoutFailedWorldPayModal: false,
  openCheckoutSuccessWorldPayModal: false,
  openPaymentMethodsSelectorModal: false,
};

export const paymentsSlice = createSlice({
  name: 'payments',
  initialState,
  reducers: {
    selectPaymentMethod: (state: PaymentsState, action: PayloadAction<PaymentMethod>) => {
      state.paymentMethod = action.payload;
    },
    storeRecentlyUsedPaymentMethod: (state: PaymentsState, action: PayloadAction<PaymentMethod>) => {
      state.recentlyUsedPaymentMethod = action.payload;
    },
    openPaymentMethodsSelectorModal: (
      state: PaymentsState,
      action: PayloadAction<PaymentMethodOrderSetTypeEnum>
    ) => {
      state.openPaymentMethodsSelectorModal = true;
      state.type = action.payload;
    },
    closePaymentMethodsSelectorModal: (state: PaymentsState) => {
      state.openPaymentMethodsSelectorModal = false;
    },
    openCheckoutFailedWorldPayModal: (
      state: PaymentsState,
      action: PayloadAction<{
        actionPurpose: string;
        result: CheckoutResult;
      }>
    ) => {
      state.openCheckoutFailedWorldPayModal = true;
      state.checkoutResultWorldPay = action.payload.result;
      state.actionPurpose = action.payload.actionPurpose;
      state.openCheckoutSuccessWorldPayModal = false;
    },
    closeCheckoutFailedWorldPayModal: (state: PaymentsState) => {
      state.openCheckoutFailedWorldPayModal = false;
    },
    openCheckoutSuccessWorldPayModal: (
      state: PaymentsState,
      action: PayloadAction<{
        actionPurpose: string;
        result: CheckoutResult;
      }>
    ) => {
      state.openCheckoutSuccessWorldPayModal = true;
      state.checkoutResultWorldPay = action.payload.result;
      state.actionPurpose = action.payload.actionPurpose;
      state.openCheckoutFailedWorldPayModal = false;
    },
    closeCheckoutSuccessWorldPayModal: (state: PaymentsState) => {
      state.openCheckoutSuccessWorldPayModal = false;
    },
  },
  extraReducers: builder => {
    builder.addCase(resetStore,
      ({ recentlyUsedPaymentMethod }: PaymentsState) => ({ ...initialState, recentlyUsedPaymentMethod })
    );
    builder.addMatcher(
      paymentsApiSlice.endpoints.getPaymentMethodOrderSets.matchFulfilled,
      (state: PaymentsState, action: PayloadAction<any>) => {
        if (action.payload) {
          state.paymentMethodOrderSet = action.payload.data.map(mapPaymentMethodOrderSet) as any;
        }
      }
    );
  },
});

export const {
  closeCheckoutFailedWorldPayModal,
  openCheckoutFailedWorldPayModal,
  openCheckoutSuccessWorldPayModal,
  closeCheckoutSuccessWorldPayModal,
  openPaymentMethodsSelectorModal,
  closePaymentMethodsSelectorModal,
  selectPaymentMethod,
  storeRecentlyUsedPaymentMethod
} = paymentsSlice.actions;

export const paymentsReducer = paymentsSlice.reducer;