import {
  FC,
  // LegacyRef,
  useEffect,
  // useRef,
  useState
} from 'react';
import {
  useMediaQuery
} from '@mui/material';
import {
  IconButton,
  Modal,
  ModalDialog,
  Stack,
  Typography,
  styled,
  useTheme
} from '@mui/joy';
import {
  useDispatch
} from 'react-redux';
import {
  CloseIcon,
  useToaster
} from '@shared/ui';
import {
  OmegaApiResponseStatusEnum
} from '@shared/api';
import {
  PaymentMethodKeyEnum
} from '@shared/types';
import {
  // selectFullProfile,
  useLazyBalanceQuery,
  // useSessionSelector
} from '@entities/session';
import {
  type PreparePurchasePackageResponse,
} from '../api';
import {
  useLazyPreparePurchasePackageQuery,
} from '../api';
import {
  discardCheckout,
  selectCoinPackage,
  useBuyCoinsSelector
} from '../model';

const CheckoutIframe = styled('iframe')(() => ({
  width: '100%',
  height: '100%',
  border: 'none',
  '@media(orientation: landscape) and (max-height: 500px)': {
    width: '100%',
    height: 'calc(100vh - 4.25rem)'
  }
}));

export type CheckoutUnipaasModalProps = {
  open: boolean;
  onDepositStart?: () => void;
  onDepositSuccess?: () => void;
  onDepositFail?: () => void;
};

export const CheckoutUnipaasModal: FC<CheckoutUnipaasModalProps> = ({
  open,
  onDepositStart,
  // onDepositFail,
  // onDepositSuccess
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(490));

  const toast = useToaster();
  const dispatch = useDispatch();

  const props = useBuyCoinsSelector(selectCoinPackage);
  const [preparePurchasePackage, setPreparePurchasePackage] = useState<Maybe<Partial<PreparePurchasePackageResponse>>>(null);

  const [getBalanceQuery] = useLazyBalanceQuery();
  const [preparePurchasePackageQuery] = useLazyPreparePurchasePackageQuery();

  useEffect(() => {
    const processDeposit = async (
      currency: string = 'USD',
      amount: number = 0,
      brandId: number = 0,
      packageId: number = 0,): Promise<void> => {
      onDepositStart?.();

      const preparePurchasePackageParams = {
        currency,
        amount,
        brandId,
        packageId,
        paymentMethod: PaymentMethodKeyEnum.Unipaas,
      };

      const preparePurchasePackageResponse = await preparePurchasePackageQuery(preparePurchasePackageParams);
      const { status, ...preparePurchasePackageProps } = preparePurchasePackageResponse.data as PreparePurchasePackageResponse;

      if ([OmegaApiResponseStatusEnum.Success, OmegaApiResponseStatusEnum.ContinueToPaymentSite].includes(status)) {
        setPreparePurchasePackage(preparePurchasePackageProps);
      } else {
        toast.error({ message: status, autoHideDuration: 5000 });
        await handleClose();
      }
    }

    const isAllowToProcessDeposit =
      props?.packageId &&
      props?.purchaseCurrency &&
      props?.purchasePrice &&
      props?.brandId &&
      open;

    if (isAllowToProcessDeposit)
      processDeposit(props?.purchaseCurrency, props?.purchasePrice, props?.brandId, props?.packageId);

    return () => {
      setPreparePurchasePackage(null);
    };
    // eslint-disable-next-line
  }, [
    props?.purchaseCurrency,
    props?.purchasePrice,
    props?.brandId,
    props?.packageId,
    open
  ]);

  const handleClose = async (): Promise<void> => {
    dispatch(discardCheckout());
    await getBalanceQuery();
  };

  // const handleDepositSuccess = async (
  //   depositResult?: {
  //     amount: string;
  //     cardNumber?: string;
  //     referenceId?: string;
  //   }
  // ): Promise<void> => {
  //   dispatch(discardCheckout());
  //   await getBalanceQuery();
  //   onDepositSuccess?.();
  // };

  // const handleDepositFail = (depositResult?: {
  //   amount: string;
  //   cardNumber?: string;
  //   referenceId?: string;
  // }
  // ): void => {
  //   dispatch(discardCheckout());
  //   onDepositFail?.();
  // };

  return open && (
    <Modal open disablePortal>
      <ModalDialog
        layout={(
          isMobile
            ? 'fullscreen'
            : 'center'
        )}
        sx={({ breakpoints, palette }) => ({
          width: 2000,
          height: '100%',
          maxWidth: 488,
          maxHeight: 749,
          backgroundColor: palette.common[925],
          [breakpoints.down(491)]: {
            maxHeight: '100%',
            borderRadius: 0,
            boxShadow: 'none',
            width: '100%'
          },
        })}
        {...props}>
        <Stack
          flexDirection='column'
          sx={({ breakpoints }) => ({
            width: '100%',
            height: '100%',
            padding: 3,
            [breakpoints.down(490)]: {
              paddingInline: 2,
              paddingTop: 2,
              paddingBottom: 4,
            },
            [breakpoints.down(390)]: {
              paddingInline: 1,
              paddingBottom: 2,
            },
          })}>
          <Stack
            direction='row'
            alignItems='center'
            justifyContent='space-between'
            sx={{
              width: '100%',
              marginBottom: 3,
            }}>
            <Typography
              level='h3'
              fontSize='1.25rem'>
              Checkout
            </Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Stack>
          <Stack
            direction='column'
            width='100%'
            height='100%'>
            {preparePurchasePackage?.continueUrl && (
              <CheckoutIframe src={preparePurchasePackage?.continueUrl} />
            )}
          </Stack>
        </Stack>
      </ModalDialog>
    </Modal>
  );
};