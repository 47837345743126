import {
  createSlice,
  PayloadAction
} from '@reduxjs/toolkit';
import {
  resetStore
} from '@shared/store-reset';
import {
  CoinPackage
} from '@entities/coin-packages';

export type BuyCoinsState = {
  currentCoinPackage: Maybe<Partial<CoinPackage>>;
  openCheckoutWorldPayModal: boolean;
  openCheckoutPaymentIQModal: boolean;
  openCheckoutUnipaasModal: boolean;
};

const initialState: BuyCoinsState = {
  openCheckoutWorldPayModal: false,
  openCheckoutPaymentIQModal: false,
  openCheckoutUnipaasModal: false,
  currentCoinPackage: null
};

export const buyCoinsSlice = createSlice({
  name: 'buy-coins',
  initialState,
  reducers: {
    pickCoinPackage: (state: BuyCoinsState, action: PayloadAction<Partial<CoinPackage>>) => {
      state.currentCoinPackage = action.payload;
    },
    discardCheckout: (state: BuyCoinsState) => {
      state.openCheckoutWorldPayModal = false;
      state.openCheckoutPaymentIQModal = false;
      state.openCheckoutUnipaasModal = false;
    },
    openCheckoutWorldPayModal: (state: BuyCoinsState) => {
      state.openCheckoutWorldPayModal = true;
    },
    openCheckoutPaymentIQModal: (state: BuyCoinsState) => {
      state.openCheckoutPaymentIQModal = true;
    },
    openCheckoutUnipaasModal: (state: BuyCoinsState) => {
      state.openCheckoutUnipaasModal = true;
    },
  },
  extraReducers: builder => {
    builder.addCase(resetStore, (state: BuyCoinsState) => {
      state.currentCoinPackage = null;
    });
  },
});

export const {
  pickCoinPackage,
  discardCheckout,
  openCheckoutPaymentIQModal,
  openCheckoutWorldPayModal,
  openCheckoutUnipaasModal,
} = buyCoinsSlice.actions;

export const buyCoinsReducer = buyCoinsSlice.reducer;