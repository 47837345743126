import {
  TypedUseSelectorHook,
  useSelector
} from 'react-redux';
import {
  UserRegistrationStatusEnum
} from '@shared/types';
import {
  type SessionState,
  sessionSlice,
  UserKycStatusEnum,
} from '@entities/session';
import {
  CoinPackage
} from '@entities/coin-packages';
import {
  type BuyCoinsState,
  buyCoinsSlice
} from './slice';

type RootState = {
  [sessionSlice.name]: SessionState;
  [buyCoinsSlice.name]: BuyCoinsState;
};

export const selectIsPlayerVerified = (state: RootState): boolean => {
  const fullProfile = state[sessionSlice.name]?.fullProfile;
  return fullProfile?.kycStatus === UserKycStatusEnum.Pass &&
    fullProfile?.registrationStatus === UserRegistrationStatusEnum.Player;
};

export const selectOpenCheckoutWorldPayModal = (state: RootState): boolean => (
  state[buyCoinsSlice.name].openCheckoutWorldPayModal
);

export const selectOpenCheckoutPaymentIQModal = (state: RootState): boolean => (
  state[buyCoinsSlice.name].openCheckoutPaymentIQModal
);

export const selectOpenCheckoutUnipaasModal = (state: RootState): boolean => (
  state[buyCoinsSlice.name].openCheckoutUnipaasModal
);

export const selectCoinPackage = (state: RootState): Maybe<Partial<CoinPackage>> => (
  state[buyCoinsSlice.name].currentCoinPackage
);

export const useBuyCoinsSelector: TypedUseSelectorHook<{
  [sessionSlice.name]: SessionState;
  [buyCoinsSlice.name]: BuyCoinsState;
}> = useSelector;