import {
  FC,
  PropsWithChildren,
} from 'react';
import {
  useGetLoyaltyPointsQuery,
  useGetPlayerInfoQuery
} from '../api';

export const WithPullingPlayerInfo: FC<PropsWithChildren> = ({ children }) => {
  useGetPlayerInfoQuery(undefined, { refetchOnMountOrArgChange: true });
  useGetLoyaltyPointsQuery(undefined, { refetchOnMountOrArgChange: true });
  return <>{children}</>
};